import { Box, Dialog, DialogActions, DialogTitle, LinearProgress, ThemeProvider, createTheme } from "@mui/material"
import { PopupDialogTypes } from "../../features/types/types"
import { useEffect, useState } from 'react'

const PopupDialog = ({open, setOpen, message, confirmMessage, dismissMessage, onConfirm, onDismiss, loading }: PopupDialogTypes) => {

    const handleClose = () => {
      setOpen(false);
    }

    const [oneButton, setOneButton] = useState(false)
    const [noButton, setNoButton] = useState(false)

    useEffect(() => {
        
      confirmMessage.length === 0 || dismissMessage.length === 0 ? setOneButton(true) : setOneButton(false)
      confirmMessage.length === 0 && dismissMessage.length === 0 ? setNoButton(true) : setNoButton(false)

    }, [confirmMessage, dismissMessage])

   return(
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
    <DialogTitle id="alert-dialog-title" style={{ padding: 25, backgroundColor: "#f8f9fa", display: "flex", flexWrap: "wrap", alignContent: "space-between" }}>
      {message}
      {loading && 
      <ThemeProvider theme={buttonsTheme}>
        <Box sx={{ width: "100%" }}>
          <LinearProgress sx={{ height: 10, borderRadius: message.length > 0 ? 5 : 0, width: message.length > 0 ? "auto" : 200, padding: 0 }} />
        </Box>
      </ThemeProvider> }
    </DialogTitle>
    {!loading && 
    <DialogActions

      style={{ display: "flex", justifyContent: oneButton ? "flex-end" : "space-around", backgroundColor: "#f8f9fa", marginRight: oneButton ? "30px" : "" }}>
      { confirmMessage.length > 0 ?
      <button className="pupupButton" onClick={() => {handleClose(); onConfirm()}}>
        { confirmMessage } 
      </button> : null }

      {dismissMessage.length > 0 ?
      <button className="pupupButton" onClick={() => {handleClose(); onDismiss()}}>
        { dismissMessage } 
      </button> : null }
      
    </DialogActions> }
  </Dialog>
   ) 
}

const buttonsTheme = createTheme({
    palette: {
      primary: {
        main: "#DDB321",
      },
      secondary: {
        main: '#808080',
      },
      text: {
        secondary: '#FFFFFF',
      },
    },
    typography: {
      fontFamily: "Roboto"
    },
  })

export default PopupDialog
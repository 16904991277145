import VrpanoOutlinedIcon from '@mui/icons-material/VrpanoOutlined';
import { Alert, Button, Container, Dialog, DialogActions, DialogTitle, LinearProgress, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow } from '@mui/material';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CreateWaterMarkStateTypes, TagPlaceEnum } from '../../features/types/types';
import jwtInterceptor from "../../auth/jwtInterceoptor";
import axios from "axios";
import moment from 'moment';
import React, { CSSProperties, useEffect, useMemo, useRef, useState } from 'react';
import UTIF from 'utif';
import { Downloader } from '../../libs/downloader';
import { addWatermarkUI, extractContext, extractWatermark, generateWatermark } from '../../libs/image';
import DisplayImage from '../DisplayImage/DisplayImage';
import ImagesList from '../ImagesList/ImagesList';
import InputImage from '../InputImage/Index';
import LoadFile from '../LoadFile/LoadFIle';
import Navbar from '../Navbar/Navbar';
import ModalPreview from '../ModalPreview';
import Tag from '../Tag/Index';
import WQuantity from '../WQuantity/Index';
import useMediaQuery from '../../features/mediaQueries/useMediaQuery';
import "./styles.css"
import ExpirationDate from '../DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import JSZip from 'jszip';
import LoadImages from '../LoadImages/LoadImages';
import PopupDialog from '../PopupDialog';
import { Table } from '@mui/material';

const sleep = (ms: number) => new Promise(r => setTimeout(r, ms));

const CreateWatermark = () => {

  const [tags, setTags] = useState<CreateWaterMarkStateTypes>({ ImageSide: [], CreateSide: [] })

  const [excelTags, setExcelTags] = useState([])

  const [carImage, setCarImage] = useState<any>(null);

  const [carImageOverride, setCarImageOverride] = useState<any>(null);

  const [file, setFile] = useState(null)

  const [quantity, setQuantity] = useState(0);

  const [retriveResult, setRetriveResult] = useState<any>();

  const [retriveProgress, setRetriveProgress] = useState(0);

  const [retriveCarImage, setRetriveCarImage] = useState<any>(null);

  const [retriveCarImageDisplay, setRetriveCarImageDisplay] = useState<any>(null);

  const [loading, setLoading] = useState(false);

  const [retriveLoading, setRetriveLoading] = useState<any>();

  const [createLoading, setCreateLoading] = useState<any>();

  const [currentExif, setCurrentExif] = useState({})

  const [zippedFolder, setZippedFolder] = useState<React.FormEvent<HTMLInputElement>>()

  const [uploadedImages, setUploadedImages] = useState<any>(null)

  const [fileNames, setFileNames] = useState<any>(null)

  const [fileTypes, setFileTypes] = useState<any>(null)

  const [confirmSendData, setConfirmSendData] = useState(false);

  const [confirmResetData, setConfirmResetData] = useState(false);

  const [checkImagesTagsNumber, setCheckImagesTagsNumber] = useState<number>(0)

  const mediumHeight = useMediaQuery("(max-height: 900px)")

  const [journalistsWithImages, setJournalistsWithImages] = useState({})

  const [preview, setPreview] = useState(false)

  const [selectedJournalist, setSelectedJournalist] = useState<number | null>(null);

  const [carouselPosition, setCarouselPosition] = useState({
    journalist: 1,
    image: 0,
  });

  const [imageBlobs, setImageBlobs] = useState<Map<string, Blob>>(new Map());

  const [redundantJournalists, setRedundantJournalists] = useState(false)

  const [scrollPos, setScrollPos] = useState(0);

  const [notes, setNotes] = useState([])

  const [expirationDate, setExpirationDate] = useState<Dayjs | null>(dayjs().add(1, 'year'));

  const [genericLoading, setGenericLoading] = useState<boolean>(false)

  const [uploadingStatus, setUploadingStatus] = useState<string>("")

  const [currentAction, setCurrentAction] = useState<string>()

  const [runningInstancesCount, setRunningInstancesCount] = useState<number | undefined>(undefined)

  const [refreshRunningInstancesCount, setRefreshRunningInstancesCount] = useState<boolean>(true)

  const [isStarting, setIsStarting] = useState<boolean>(false);

  const [isStopping, setIsStopping] = useState<boolean>(false);


  const onAction = (action?: string) => {
    setCurrentAction(action)
  }

  useEffect(() => {
    if (file) setPreview(true)
  }, [file])

  const uri = `/api/getRunningInstancesCount`

  useEffect(() => {
    if (refreshRunningInstancesCount) {
      axios.post(
        `${process.env.REACT_APP_API_HOST || ""}${uri}`,
        {},
        { withCredentials: true }
      ).then(({ data: { count } }) => {
        if (!isStarting && !isStopping) {
          setRunningInstancesCount(count)
        }
        setRefreshRunningInstancesCount(false)
        setTimeout(() => {
          setRefreshRunningInstancesCount(true)
        }, 60000)
      })
    }
  }, [refreshRunningInstancesCount])


  const startAllInstances = () => {
    doTheStartAllInstancesCall().then(() => {
      setRefreshRunningInstancesCount(true)
      setIsStarting(false);
    })
    setRefreshRunningInstancesCount(false)
    setIsStarting(true)

  }

  const doTheStartAllInstancesCall = () => {
    return axios.post(
      `${process.env.REACT_APP_API_HOST || ""}/api/startAllInstances`,
      {},
      { withCredentials: true, timeout: 4 * 60 * 1000 }
    )
  }

  const stopAllInstances = () => {
    doTheStopAllInstancesCall().then(() => {
      // setRefreshRunningInstancesCount(true)
      setIsStopping(false)
    })
    setIsStopping(true)
    setRunningInstancesCount(undefined);
    setRetriveResult(null);
    setQuantity(0);
    setRetriveCarImage(null);
    setRetriveCarImageDisplay(null);
    setRetriveLoading(null);
    (
      document.getElementById(
        "retriveImage"
      ) as HTMLInputElement
    ).value = "";
  }

  const doTheStopAllInstancesCall = () => {
    return axios.post(
      `${process.env.REACT_APP_API_HOST || ""}/api/_____UNSAFE____stopAllInstances`,
      {},
      { withCredentials: true }
    )
  }



  //canvas
  const processingCanvas = useRef<any>();
  const txtCanvas = useRef<any>();
  const tiffUtilCanvas = useRef<any>();

  /* UTILS  */

  function _resizeCanvasesToImg(img: any) {
    if (processingCanvas?.current) {
      processingCanvas.current.width = img.width;
      processingCanvas.current.height = img.height;
    }
    if (txtCanvas?.current) {
      txtCanvas.current.width = img.width;
      txtCanvas.current.height = img.height;
    }
  }

  function togglePreview() {
    if (preview) {
      if (selectedJournalist) setCarouselPosition({ image: carouselPosition.image, journalist: selectedJournalist })
      setPreview(false);
    } else {
      setPreview(true);
    }
  }

  function resetData() {
    if (confirmResetData) {
      setRedundantJournalists(false);
      setExcelTags([]);
      setTags({ ImageSide: [], CreateSide: [] });
      setCarImage(null);
      setUploadedImages(null);
      setFile(null);
      setCreateLoading(null);
      setQuantity(0);
      setZippedFolder(undefined);
      setCheckImagesTagsNumber(0);
      setFileNames(null);
      setJournalistsWithImages({});
      setPreview(false);
      setSelectedJournalist(null);
      setCarouselPosition({ image: 0, journalist: 0 });
      (
        document.getElementById("multipleImages") as HTMLInputElement
      ).value = "";
      (
        document.getElementById("file") as HTMLInputElement
      ).value = "";
      setConfirmResetDataDialog(false)
      setConfirmResetData(false)
      setScrollPos(0)
      setNotes([])
      setExpirationDate(dayjs().add(1, 'year'))
    }
  }

  function sendFile(url: string, file: File, method: string) {
    console.log(file)
    return new Promise(function (resolve, reject) {
      var xhr = new XMLHttpRequest();
      xhr.open(method, url);
      xhr.onload = function () {
        if (this.status >= 200 && this.status < 300) {
          resolve(xhr.response);
        } else {
          reject({
            status: this.status,
            statusText: xhr.statusText
          });
        }
      };
      xhr.onerror = function () {
        reject({
          status: this.status,
          statusText: xhr.statusText
        });
      };
      xhr.send(file);
    });
  }

  function _updateRetriveProgress(progress: any) {
    console.log("progress", progress);
    setRetriveProgress(progress * 100);
  }

  function tiffArrayBufferToObjectUrl(data: ArrayBuffer) {
    var ifds = UTIF.decode(data);
    var ifd = ifds[0];
    UTIF.decodeImage(data, ifd);
    var rgba = UTIF.toRGBA8(ifd);
    let tiffUtilCanvasContext = extractContext(tiffUtilCanvas.current);
    tiffUtilCanvas.current.width = ifd.width;
    tiffUtilCanvas.current.height = ifd.height;
    var imageData = tiffUtilCanvasContext.createImageData(ifd.width, ifd.height);
    for (let i = 0; i < rgba.length; i++) {
      imageData.data[i] = rgba[i];
    }
    tiffUtilCanvasContext.putImageData(imageData, 0, 0);
    return tiffUtilCanvas.current.toDataURL();
  }


  /* Create the Carousel  */
  useEffect(() => {
    let myBlobs: any = []
    let myFileNames: any = []
    let fileType: any = []
    if (zippedFolder) {
      JSZip.loadAsync((zippedFolder.target as any).files[0]).then(async function (zip) {
        for (let file of Object.entries(zip.files)) {
          if (file[0].endsWith(".tiff") || file[0].endsWith(".tif")) { //weak
            let data = await zip.file(file[0])?.async("arraybuffer")
            if (data) {
              myBlobs.push(tiffArrayBufferToObjectUrl(data));
              myFileNames.push(file[0])
              fileType.push(new Blob([data]).type)
            }
          } else {
            let data = await zip.file(file[0])?.async("uint8array")
            if (data) {
              var blob = new Blob([data]);
              myBlobs.push(URL.createObjectURL(blob))
              myFileNames.push(file[0])
              fileType.push(blob.type)
            }
          }
        }
      }).then(() => {
        setUploadedImages(myBlobs)
        setFileNames(myFileNames)
        setFileTypes(fileType)
        setQuantity(1)
      }).catch(function (err) {
        console.log("Failed to open", err)
      })
    } else {
      if (carImage) {
        const data = carImage.target.files[0]
        if (data) {
          if (data.type.toLowerCase() === 'image/tiff' || data.type.toLowerCase() === 'image/tif') {
            const fileReader = new FileReader();
            fileReader.onload = function (event) {
              let abData = event.target?.result;
              myBlobs.push(tiffArrayBufferToObjectUrl(abData as ArrayBuffer));
              setUploadedImages(myBlobs);
              setQuantity(0);
            };
            fileReader.readAsArrayBuffer(data);
          } else {
            var blob = new Blob([data])
            myBlobs.push(URL.createObjectURL(blob))
            setUploadedImages(myBlobs);
            setQuantity(0);
          }
        }
      }
    }
  }, [zippedFolder, carImage])


  useEffect(() => setTags(oldTags => {
    let newTags = JSON.parse(JSON.stringify(oldTags))
    let emptyArrays = new Array(Number(quantity)).fill([])
    let imageSideTags = emptyArrays.map((e, index) => oldTags.ImageSide[index] || [])
    if (uploadedImages) {
      for (let i = 0; i < excelTags.length; i++) {
        imageSideTags.push(excelTags[i]);
      }
    }
    newTags.ImageSide = imageSideTags
    return newTags
  }), [quantity, excelTags])
  useEffect(() => {
    if (carImage) onImageUpload(carImage.target.files[0]);
  }, [carImage])


  async function convertToBlob(objectUrl: string) {
    return await fetch(objectUrl).then(r => r.blob());
  }

  let payload = new FormData();

  async function sendData() {
    for (let index = 0; index < tags.ImageSide.length; index++) {
      const imageTags: any = tags.ImageSide[index]
      const imageNotes: any = notes[index]
      payload.append('tags', imageTags)
      payload.append('notes', imageNotes)
    }

    if (expirationDate) payload.append('expiration_date', expirationDate.format('YYYY-MM-DD'))

    function sentPayload() {
      setConfirmSendData(false)
      handleClickOpen();
      setConfirmResetData(true)
      resetData();
    }

    if (confirmSendData === true && uploadedImages) {
      setLoadingDialog(true)
      let counter = fileNames.length + 1
      for (let image of uploadedImages) {
        const blob = await convertToBlob(image);
        const fileName = fileNames[fileNames.length - counter + 1];
        const fullPath = await uploadFile(blob, fileName, (loaded, total) => {
          const progress = (loaded / total) * 100;
          setUploadingStatus(`Sending data ${uploadedImages.length - counter + 1}/${uploadedImages.length} ${progress.toFixed(2)} %`)
        });
        payload.append('images', fullPath);
        counter--
      }
      console.log(payload);
      jwtInterceptor.post(
        `${process.env.REACT_APP_API_HOST || ""}/api/job1`,
        payload,
        {
          onUploadProgress: (progressEvent: any & { loaded: number; total: number }) => {
            if (progressEvent.total > 0) {
              let progress = (progressEvent.loaded / progressEvent.total) * 100;
              console.log(progress)
              setUploadingStatus(`Sending data: ${progress.toFixed(2)} %`)
              if (progress === 100) setUploadingStatus("Finishing...")
            } else {
              console.error(progressEvent)
              setUploadingStatus("Sending data...")
            }
          },
          withCredentials: true
        }
      ).then(({ data: { batch_id } }) => {
        setUploadingStatus(`Starting encoding`)
        doTheStartAllInstancesCall().then(() => {
          console.log(`Instances started`)
        })
          .then(() => {
            setTimeout(() => {
              axios.post(
                `${process.env.REACT_APP_API_HOST || ""}/api/job2?batch_id=${batch_id}`,
                { batch_id },
                { withCredentials: true }
              )
            }, 10000)
          }
          )
      })
        .then(response => {
          setLoadingDialog(false)
          sentPayload()
        }).catch(error => {
          setLoadingDialog(false)
          console.error(`error ${error}`)
        }).finally(() => {
          setLoadingDialog(false)
          sentPayload()
        });
    } else { }
  }

  useEffect(() => {
    try {
      sendData()
    } catch (err) {
      doTheStopAllInstancesCall().then(() => {
        console.log(`Instances stopped`)
      })
    }
  }, [confirmSendData])

  useEffect(() => {
    resetData()
  }, [confirmResetData])

  useEffect(() => {
    if (retriveCarImage && retriveCarImage?.target) onImageUpload(retriveCarImage.target.files[0], 'retrive');
  }, [retriveCarImage])

  const [isCreate, setIsCreate] = useState<boolean>(true)

  const displayContent = () => {
    if (redundantJournalists) {
      return <Alert severity="warning">Different journalists with the same name not allowed</Alert>
    } else if (checkImagesTagsNumber !== 0 && (uploadedImages && checkImagesTagsNumber !== uploadedImages.length)) {
      return <Alert severity="warning">Check excel or zipped folder again. Every journalist needs as many excel rows of tags as images uploaded!</Alert>
    } else if ((!file && zippedFolder) || (file && (uploadedImages && checkImagesTagsNumber === uploadedImages.length)) && !preview) {
      return <ImagesList imagePosition={carouselPosition.image}
        journalistPosition={carouselPosition.journalist}
        setJournalistsWithImages={setJournalistsWithImages}
        uploadedImages={uploadedImages}
        file={file}
        excelTags={excelTags}
        carImage={carImage} setCarImage={setCarImage}
        quantity={quantity}
        tag={tags[TagPlaceEnum.ImageSide]}
        overrideTiffCanvas={carImageOverride}
        checkImagesTagsNumber={checkImagesTagsNumber}
        setTags={setTags}
        preview={preview}
        setPreview={setPreview}
        notes={notes}
        setNotes={setNotes}
        setGenericLoading={setGenericLoading}
      />
    } else if (file && zippedFolder && preview) {
      return <ModalPreview uploadedImages={uploadedImages}
        selectedJournalist={selectedJournalist}
        setSelectedJournalist={setSelectedJournalist}
        setCarouselPosition={setCarouselPosition}
        setPreview={setPreview}
        excelTags={excelTags}
        tag={tags[TagPlaceEnum.ImageSide]}
        imageBlobs={imageBlobs}
        setImageBlobs={setImageBlobs}
        setRedundantJournalists={setRedundantJournalists}
        scrollPos={scrollPos}
        setScrollPos={setScrollPos}
        genericLoading={genericLoading}
        setGenericLoading={setGenericLoading}
      />
    } else {
      return <VrpanoOutlinedIcon style={{ fontSize: 300, color: '#404040' }} />
    }
  }

  const resetButtonLogic = () => {
    if (file && !redundantJournalists) {
      setConfirmResetDataDialog(true)
    } else {
      setConfirmResetData(true)
      resetData()
    }
  }

  /* Create the processing canvas */
  const onImageUpload = async (file: any, type?: string, callBack?: (data: ArrayBuffer) => void) => {
    return new Promise<Buffer>((resolve, reject) => {


      setLoading(true)

      const fileReader = new FileReader();
      if (file) {

        fileReader.onload = async function (event) {
          if (event.target?.result instanceof ArrayBuffer) {
            var ifds = UTIF.decode(event.target?.result);
            var ifd = ifds[0];
            UTIF.decodeImage(event.target?.result, ifd);
            var rgba = UTIF.toRGBA8(ifd);
            /* create canvas for image manipulation */
            _resizeCanvasesToImg(ifd);
            const processingCtx = extractContext(processingCanvas.current);
            var imageData = processingCtx.createImageData(ifd.width, ifd.height);
            for (let i = 0; i < rgba.length; i++) {
              imageData.data[i] = rgba[i];
            }
            processingCtx.putImageData(imageData, 0, 0);
            if (type == 'retrive') {
              setRetriveCarImageDisplay(processingCanvas.current.toDataURL())
            }
            setLoading(false);
            callBack && (await callBack(file))
            resolve(file)

          } else {
            const newImg: any = new Image();
            newImg.onload = async function () {
              _resizeCanvasesToImg(newImg);
              const processingCtx = extractContext(processingCanvas.current);
              processingCtx.drawImage(newImg, 0, 0);
              setLoading(false);

              console.log(file)
              callBack && (await callBack(file))

              if (type == 'retrive') {
                setRetriveCarImageDisplay(retriveCarImage);
              }

              resolve(file)

            };
            newImg.src = (event.target || { result: "" }).result;
          }
          //setCurrentExif(piexif.load(newImg.src))
        };

        if (file.type.toLowerCase() === 'image/tiff' || file.type.toLowerCase() === 'image/tif') {
          fileReader.readAsArrayBuffer(file);
        } else {
          fileReader.readAsDataURL(file);
        }

      }
    })
  }

  useEffect(() => {
    if ((Object.keys(journalistsWithImages as any).length) && preview) {
      setPreview(true)
    }
  }, [journalistsWithImages])

  //Succccess dialog
  const [creationLaunched, setCreationLaunched] = useState(false);

  const [confirmSendDataDialog, setConfirmSendDataDialog] = useState(false)

  const [confirmResetDataDialog, setConfirmResetDataDialog] = useState(false)

  const [loadingDialog, setLoadingDialog] = useState(false)

  const handleConfirmSendData = () => {
    setConfirmSendData(true)
  }

  const handleOpenConfirmSendData = () => {
    setConfirmSendDataDialog(true)
  }

  const handleCloseConfirmSendData = () => {
    setConfirmSendDataDialog(false)
  }

  const handleConfirmResetData = () => {
    setConfirmResetData(true)
  }

  const handleOpenResetData = () => {
    setConfirmResetDataDialog(true)
  }

  const handleCloseResetData = () => {
    setConfirmResetDataDialog(false)
  }

  const handleClickOpen = () => {
    setCreationLaunched(true);
  }

  const handleClose = () => {
    setCreationLaunched(false);
  };

  if (currentAction == 'downloads') {

    return <div style={styles.container}>
      <Navbar currentAction={currentAction} onAction={onAction} />
      <DownloadsComponent />

    </div>
  }

  return isCreate ? (
    <div style={styles.container}>
      <Navbar onAction={onAction} />

      {confirmSendDataDialog &&
        <PopupDialog open={confirmSendDataDialog}
          setOpen={handleCloseConfirmSendData}
          message={"Are you sure you want to launch the download?"}
          confirmMessage={"Yes"}
          dismissMessage={"Cancel"}
          onDismiss={handleCloseConfirmSendData}
          onConfirm={handleConfirmSendData}
          loading={loading}
        />}

      {creationLaunched &&
        <PopupDialog open={creationLaunched}
          setOpen={handleClose}
          message={`Creation successfully launched! Please click on DOWNLOAD button to monitor and download signed images.`}
          confirmMessage={""}
          dismissMessage={"Close"}
          onDismiss={handleClose}
        onConfirm={() => { }}
        loading={loading}
        />}

      {confirmResetDataDialog &&
        <PopupDialog open={confirmResetDataDialog}
          setOpen={handleClose}
          message={`Are you sure you want to reset data?`}
          confirmMessage={"Reset"}
          dismissMessage={"No"}
          onDismiss={handleCloseResetData}
          onConfirm={handleConfirmResetData}
          loading={loading}
        />}

      {loadingDialog &&
        <PopupDialog open={loadingDialog}
        setOpen={() => { }}
        message={`${uploadingStatus}`}
        confirmMessage={""}
        dismissMessage={""}
        onDismiss={() => { }}
        onConfirm={() => { }}
        loading={loadingDialog}
        />}

      {genericLoading &&
        <PopupDialog open={genericLoading}
        setOpen={() => { }}
        message={``}
        confirmMessage={""}
        dismissMessage={""}
        onDismiss={() => { }}
        onConfirm={() => { }}
        loading={genericLoading}
        />}

      <Container maxWidth="xl">
        <div id="canvas-wrapper" style={{ display: "none" }}>
          <canvas id="createProcessingCanvas" ref={processingCanvas}></canvas>
          <canvas id="createTxtCanvas" ref={txtCanvas}></canvas>
          <canvas id="tiffUtilCanvas" ref={tiffUtilCanvas}></canvas>
        </div>
        <div style={styles.wrapper}>

          <div style={(checkImagesTagsNumber !== 0 && (uploadedImages && checkImagesTagsNumber !== uploadedImages.length)) || redundantJournalists ? styles.warningDisplay : !preview && mediumHeight ? styles.mediumSlider : !preview ? styles.slider : styles.sliderPreview}>
            {displayContent()}
          </div>
          <div style={mediumHeight ? styles.mediumRightContainer : styles.rightContainer}>
            <div style={styles.menuContainer}>
              <div>
                <ThemeProvider theme={theme}>
                  <Button variant="contained" style={styles.selectionC}>
                    Create Watermark
                  </Button>
                </ThemeProvider>
              </div>
              <div>
                <ThemeProvider theme={theme}>
                  <Button
                    variant="outlined"
                    style={styles.selectionR}
                    onClick={() => setIsCreate(false)}
                  >
                    Retrieve Watermark
                  </Button>
                </ThemeProvider>
              </div>
            </div>

            <div style={{ marginBottom: "25px", display: "flex", flexDirection: "row", justifyContent: "start", marginTop: 10 }}>

              <LoadImages
                zippedFolder={zippedFolder}
                setZippedFolder={setZippedFolder}
                inputId="multipleImages"
                setGenericLoading={setGenericLoading}
              />

            </div>

            <div style={styles.quantityInput}>
              <LoadFile zippedFolder={zippedFolder}
                inputId="file" file={file}
                setFile={setFile}
                excelTags={excelTags}
                setExcelTags={setExcelTags}
                uploadedImages={uploadedImages}
                carImage={carImage}
                setQuantity={setQuantity}
                setCheckImagesTagsNumber={setCheckImagesTagsNumber}
                checkImagesTagsNumber={checkImagesTagsNumber}
                setNotes={setNotes}
              />
            </div>

            <ExpirationDate expirationDate={expirationDate} setExpirationDate={setExpirationDate} file={file} />

            <div style={styles.watermarkTextInput}>
              <div style={{ display: "flex", alignContent: "center", justifyContent: "start" }}>
                {
                  <p style={{ color: (file && zippedFolder) && (uploadedImages && checkImagesTagsNumber == uploadedImages.length) ? "#FFF" : "#6c757d", fontSize: 18, fontWeight: "bold", marginBottom: "5px" }}>
                    Add tags to all the images
                  </p>
                }
              </div>

              <Tag
                file={file}
                excelTags={excelTags}
                tag={tags[TagPlaceEnum.Create]}
                setTags={setTags}
                TagPlace={TagPlaceEnum.Create}
                uploadedImages={uploadedImages}
                checkImagesTagsNumber={checkImagesTagsNumber}
              />

            </div>

            <div style={styles.createSideButtonsWrapper}>

              <ThemeProvider theme={buttonsTheme}>
                {file && selectedJournalist || selectedJournalist === 0 ?
                  <Button
                    variant="outlined"
                    color="primary"
                    style={styles.toggleButton}
                    onClick={async () => togglePreview()}
                  >
                    {preview ? "view carousel" : "view list"}
                  </Button> : ''}
              </ThemeProvider>

              <div style={styles.onCreate}>
                {(file && zippedFolder) && (uploadedImages && checkImagesTagsNumber === uploadedImages.length) && !redundantJournalists ?
                  <div>
                    <ThemeProvider theme={buttonsTheme}>
                      <Button
                        variant="outlined"
                        color="primary"
                        style={styles.buttonSubmit}
                        onClick={async () => {
                          if (!uploadedImages) {
                            return;
                          }
                          handleOpenConfirmSendData()
                        }}
                      >
                        START CREATION
                      </Button>
                    </ThemeProvider>
                  </div>
                  : ''}

                <ThemeProvider theme={buttonsTheme}>
                  {file || zippedFolder ?
                    <Button
                      variant="outlined"
                      color="primary"
                      style={styles.buttonSubmit}
                      onClick={async () => resetButtonLogic()}
                    >
                      Reset
                    </Button> : ''}
                </ThemeProvider>
              </div>
              <div style={{ marginTop: 25 }}>
                {createLoading === "loading" ? (
                  <ThemeProvider theme={buttonsTheme}>
                    <Box sx={{ width: "100%", marginBottom: 3 }}>
                      <LinearProgress sx={{ height: 10, borderRadius: 5, }} />
                    </Box>
                  </ThemeProvider>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  ) : (
    <div style={styles.container}>
        <Navbar onAction={onAction} />
      <Container maxWidth="xl">
        <div style={styles.wrapper}>
          <div style={{ display: "none" }}>
            <div id="canvas-wrapper" style={{ display: "none" }}>
              {loading ? (
                <img id="loading-img" className="hidden" alt="Loading" />
              ) : null}
              <canvas
                id="retriveProcessingCanvas"
                ref={processingCanvas}
              ></canvas>
              <canvas id="retriveProcessingCanvas" ref={txtCanvas}></canvas>
            </div>
          </div>
          <div style={mediumHeight ? styles.mediumSlider : styles.slider}>


            {retriveCarImageDisplay ?
              <DisplayImage carImage={retriveCarImageDisplay}></DisplayImage>
              :
              <VrpanoOutlinedIcon style={{ fontSize: 300, color: '#404040' }} />
            }

          </div>
          <div style={mediumHeight ? styles.mediumRetrieveRightContainer : styles.rightRetrieveContainer}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={styles.menuContainer}>
                  <div>
                    <ThemeProvider theme={theme}>
                      <Button
                        variant="outlined"
                        style={styles.selectionR}
                        onClick={() => setIsCreate(true)}
                      >
                        Create Watermark
                      </Button>
                    </ThemeProvider>

                  </div>
                  <div>
                    <ThemeProvider theme={theme}>
                      <Button variant="contained" style={styles.selectionC}>
                        Retrieve Watermark
                      </Button>
                    </ThemeProvider>
                </div>
              </div>

              <div style={styles.carImage}>
                <p style={{ color: retriveCarImage ? "#808080" : "#FFFFFF", paddingRight: 20, fontSize: 18, fontWeight: "bold" }}>Upload image:</p>
                <InputImage
                  carImage={retriveCarImage}
                  setCarImage={setRetriveCarImage}
                  inputId="retriveImage"
                />
              </div>
              </div>

              {retriveLoading === "loading" || isStarting || isStopping ? (
              <ThemeProvider theme={buttonsTheme}>
                <Box sx={{ width: "100%", marginBottom: 3 }}>
                  <LinearProgress sx={{ height: 10, borderRadius: 5 }} />
                </Box>
              </ThemeProvider>
              ) : (
                  retriveLoading === "done" ? (
              <ThemeProvider theme={buttonsTheme}>
                <Box sx={{ width: "100%", marginBottom: 3 }}>
                  <LinearProgress
                    variant="determinate"
                    sx={{ height: 10, borderRadius: 5 }}
                    value={100}
                  />
                </Box>
              </ThemeProvider>
            ) : (
              ""
                ))}

            {retriveResult && retriveResult === "ERROR" ? (
              <div style={{ marginTop: 30 }}>
                <div style={styles.errorInput}>
                  <div>
                    <h3 style={{ color: "#FF0000", fontWeight: "bold" }}>
                      NO WATERMARK DETECTED!
                    </h3>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <div style={{ maxHeight: '339px', overflowY: 'auto', }}>
              {retriveResult && retriveResult !== "ERROR" ? retriveResult.map(
                (el: any) => (
                  <div key={el?.watermark_id}>
                    <br></br>
                    <div style={styles.quantityInput}>
                      <div>
                        <h3
                          style={{
                            color: "#FFFFFF",
                            display: "inline",
                            marginRight: 10,
                          }}
                        >
                          Author:
                        </h3>
                        <span style={{ color: "#FFFFFF" }}>
                          {el?.author}
                        </span>
                      </div>
                    </div>

                    <div style={styles.quantityInput}>
                      <div>
                        <h3
                          style={{
                            color: "#FFFFFF",
                            display: "inline",
                            marginRight: 10,
                          }}
                        >
                          Watermark ID:
                        </h3>
                        <span style={{ color: "#FFFFFF" }}>
                          {parseInt(el?.watermark_id, 2).toString(16)}
                        </span>
                      </div>
                    </div>

                    <div style={styles.watermarkTextInput}>
                      <div>
                        <h3
                          style={{
                            color: "#FFFFFF",
                            display: "inline",
                            marginRight: 10,
                          }}
                        >
                          Tag(s):
                        </h3>
                        <span style={{ color: "#FFFFFF" }}>
                          {el?.tag}
                        </span>
                      </div>
                    </div>

                    <div style={styles.watermarkTextInput}>
                      <div>
                        <h3
                          style={{
                            color: "#FFFFFF",
                            display: "inline",
                            marginRight: 10,
                          }}
                        >
                          Date:
                        </h3>
                        <span style={{ color: "#FFFFFF" }}>
                          {moment(el?.watermark_creation_date).format(
                            "DD/MM/YYYY"
                          )}
                        </span>
                      </div>
                    </div>

                    <div style={styles.quantityInput}>
                      <div>
                        <h3
                          style={{
                            color: "#FFFFFF",
                            display: "inline",
                            marginRight: 10,
                          }}
                        >
                          Accuracy:
                        </h3>
                        <span style={{ color: "#FFFFFF" }}>
                          {Number(el?.accuracy).toFixed(2)}%
                        </span>
                      </div>
                    </div>

                    <div style={styles.watermarkTextInput}>
                      <div>
                        <h3
                          style={{
                            color: "#FFFFFF",
                            display: "inline",
                            marginRight: 10,
                          }}
                        >
                          Notes:
                        </h3>
                        <span style={{ color: "#FFFFFF" }}>
                          {el?.note}
                        </span>
                      </div>
                    </div>
                  </div>
                )) : (
                ""
              )}
            </div>
              {!runningInstancesCount ? (<div>
                <ThemeProvider theme={buttonsTheme}>
                  <Button
                    variant="outlined"
                    color={isStarting || isStopping ? "secondary" : "primary"}
                    style={styles.buttonSubmit}
                    onClick={() => !isStarting && !isStopping && startAllInstances()} >Start Retrieve Server</Button>
                </ThemeProvider>

              </div>)
                : <div style={styles.onRetrieve}>
                  {!retriveResult ? (
                    <ThemeProvider theme={buttonsTheme}>
                      <Button
                        variant="outlined"
                        color={retriveCarImage ? "primary" : "secondary"}
                        style={styles.buttonSubmit}

                        onClick={retriveCarImage ? async () => {
                          setRetriveLoading("loading");



                        try {
                          const fileName = retriveCarImage.target.files[0].name;
                          //Uplad file to cs
                          // const file = new File([imgresponses], fileName, { type: 'image/jpg' });
                          const file = retriveCarImage.target.files[0];
                          // const uploadResponse = await sendFile(signedUrl, file, 'PUT');
                          const formData = new FormData();
                          formData.append('file', file, fileName)

                          const response = await jwtInterceptor.post(
                            (process.env.REACT_APP_API_HOST || "") +
                            "/api/retrievewatermark",

                            formData
                          );
                            if (response.data?.length) {
                              setRetriveResult(response.data);
                            } else {
                              console.log("no results for this tag");
                              setRetriveResult("ERROR");
                            }
                            setRetriveLoading("done");
                          } catch (err) {
                            console.error(err);
                            setRetriveLoading("done");
                          }
                        } : () => { }}
                      >
                        Retrieve
                      </Button>
                    </ThemeProvider>
                  ) : (
                    ""
                  )}
                  <ThemeProvider theme={buttonsTheme}>
                    <Button
                      variant="outlined"
                      color={retriveCarImage ? "primary" : "secondary"}
                      style={styles.buttonSubmit}
                      onClick={async () => {
                        setRetriveResult(null);
                        setQuantity(0);
                        setRetriveCarImage(null);
                        setRetriveCarImageDisplay(null);
                        setRetriveLoading(null);
                        (
                          document.getElementById(
                            "retriveImage"
                          ) as HTMLInputElement
                        ).value = "";
                      }}
                    >
                      Reset
                    </Button>
                  </ThemeProvider>
                  <ThemeProvider theme={buttonsTheme}>
                    <Button
                      variant="outlined"
                      color={isStopping ? "secondary" : "primary"}
                      style={styles.buttonSubmit}
                      disabled={isStopping}
                      onClick={() => stopAllInstances()}
                    >
                      Stop Server
                    </Button>
                  </ThemeProvider>
                </div>}
          </div>
        </div>
      </Container>
    </div>
  );
}

interface Batch {
  batch_id: number
  done: number
  status: number
  total: number
  urlBatch: string
  created_at: string
}

const DownloadsComponent = ({ }) => {
  const [batches, setBatches] = useState<Array<Batch>>();
  const [refresh, setRefresh] = useState<number>(1);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    axios.get(
      (process.env.REACT_APP_API_HOST || "") +
      "/api/batches",
      { withCredentials: true }
    ).then(({ data }) => {
      setBatches(data)
      setTimeout(() => {
        setRefresh(+new Date)
      }, 30000)
    });
  }, [refresh])

  return <ThemeProvider theme={buttonsTheme}>
    <Container maxWidth="xl">
      <div style={{ color: 'white', margin: 'auto', width: '100%', textAlign: 'center' }}>
        My Downloads
        <Button onClick={() => setRefresh(+new Date)}>Refresh</Button>
        <Table style={{ color: 'white' }}>
          <TableHead style={{ color: 'white' }}>
            <TableRow style={{ color: 'white' }}>
              <TableCell style={{ textAlign: 'center', color: 'white' }}>ID</TableCell>
              <TableCell style={{ textAlign: 'center', color: 'white' }}>Date</TableCell>
              <TableCell style={{ textAlign: 'center', color: 'white' }}>Status</TableCell>
              <TableCell style={{ textAlign: 'center', color: 'white' }}>Downloads</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {batches?.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((el) => {
              const date = new Date(el.created_at).toLocaleString();

              return <TableRow>
                <TableCell style={{ textAlign: 'center', color: 'white' }}>{el.batch_id}</TableCell>
                <TableCell style={{ textAlign: 'center', color: 'white' }}>{date}</TableCell>
                <TableCell style={{ textAlign: 'center', color: 'white' }}>{el.done} / {el.total}</TableCell>
                <TableCell style={{ textAlign: 'center', color: 'white' }}>{el.status == 2 ?
                  <Button href={el.urlBatch}>Download full batch {el.batch_id} </Button>
                  : null}</TableCell>
              </TableRow>
            })}
          </TableBody>
          <TableFooter>
            <TableRow style={{ width: '100%' }}>
              <TableCell colSpan={4}>
                <TablePagination
                  colSpan={4}
                  style={{ backgroundColor: 'white', textAlign: 'center', width: '100%' }}
                  onRowsPerPageChange={({ target: { value } }) => setRowsPerPage(parseInt(value))}
                  count={batches?.length || 0} rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={(evt, page) => setPage(page)} component={'div'} />
              </TableCell>
            </TableRow></TableFooter>
        </Table>

      </div>
    </Container>

  </ThemeProvider>
}

const styles: Record<string, CSSProperties> = {
  container: {
    backgroundColor: "#181818",
    fontFamily: "Roboto",
    minHeight: '100vh'
  },
  wrapper: {
    backgroundColor: "#181818",
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
    justifyContent: "center",
  },
  menuContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  rightContainer: {
    display: "flex",
    flexDirection: "column",
    minWidth: 460,
    maxWidth: 460,
    minHeight: 610,
    border: '1px solid #6f6f6f',
    boxShadow: '0 8px 8px -4px #6f6f6f',
    borderRadius: 10,
    padding: 20,
    margin: 5,
    backgroundColor: "#000"
  },
  rightRetrieveContainer: {
    display: "flex",
    flexDirection: "column",
    minWidth: 460,
    maxWidth: 460,
    minHeight: 610,
    border: '1px solid #6f6f6f',
    boxShadow: '0 8px 8px -4px #6f6f6f',
    borderRadius: 10,
    padding: 20,
    margin: 5,
    backgroundColor: "#000"
  },
  mediumRetrieveRightContainer: {
    display: "flex",
    flexDirection: "column",
    minWidth: 398,
    maxWidth: 460,
    maxHeight: 615,
    border: '1px solid #6f6f6f',
    boxShadow: '0 8px 8px -4px #6f6f6f',
    borderRadius: 10,
    padding: 20,
    margin: 5,
    backgroundColor: "#000"
  },
  mediumRightContainer: {
    display: "flex",
    flexDirection: "column",
    minWidth: 398,
    maxWidth: 460,
    maxHeight: 615,
    border: '1px solid #6f6f6f',
    boxShadow: '0 8px 8px -4px #6f6f6f',
    borderRadius: 10,
    padding: 20,
    margin: 5,
    backgroundColor: "#000"
  },
  mediumSlider: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    flexGrow: 4,
    border: '1px solid #6f6f6f',
    boxShadow: '0 8px 8px -4px #6f6f6f',
    borderRadius: 10,
    padding: 5,
    minHeight: 540,
    maxHeight: 615,
    margin: 5,
    backgroundColor: "#000",
    maxWidth: 900
  },
  warningDisplay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    flexGrow: 4,
    border: '1px solid #6f6f6f',
    boxShadow: '0 8px 8px -4px #6f6f6f',
    borderRadius: 10,
    padding: 5,
    minHeight: 540,
    maxHeight: 615,
    margin: 5,
    backgroundColor: "#000",
    maxWidth: 900
  },
  sliderPreview: {
    display: "flex",
    border: '1px solid #6f6f6f',
    boxShadow: '0 8px 8px -4px #6f6f6f',
    borderRadius: "10px",
    minHeight: "540px",
    maxHeight: "615px",
    margin: "5px",
    backgroundColor: "rgb(0, 0, 0)",
    width: "900px",
  },
  slider: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    flexGrow: 4,
    border: '1px solid #6f6f6f',
    boxShadow: '0 8px 8px -4px #6f6f6f',
    borderRadius: 10,
    padding: 20,
    minHeight: 610,
    margin: 5,
    backgroundColor: "#000",
    width: 1100,
    maxHeight: 720
  },
  carImage: {
    display: "flex",
    alignItems: "center",
    marginTop: 30,
    marginBottom: 10,
    width: "460px",
  },
  containerFormTitle: {
    display: "flex",
    flexDirection: "column",
  },
  watermarkTextInput: {
    display: "flex",
    flexDirection: "column",
  },
  errorInput: {
    display: "flex",
    flexDirection: "column",
  },
  buttonSubmit: {
    display: "block",
    borderRadius: 10,
    minWidth: 100,
    maxWidth: 200,
    marginRight: 10,
    fontFamily: "Roboto",
  },
  selectionC: {
    color: "#000000",
    fontWeight: 'bold',
    fontSize: '1rem',
  },
  selectionR: {
    color: "#FFFFFF",
    fontWeight: 'bold',
    fontSize: '1rem',
  },
  menuButtonsContainer: {
    // marginLeft:40
  },
  onCreate: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    fontFamily: "Roboto"
  },
  onRetrieve: {
    display: "flex",
    flexDirection: "row",
    marginTop: 30,
    justifyContent: "space-around",
    fontFamily: "Roboto"
  },
  wrongExcelMessage: {
    width: 800,
    height: 600,
    backgroundColor: "#e9c46a",
    display: "flex",
    fontFamily: "Roboto"
  },
  createSideButtonsWrapper: {
    marginTop: 20,
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    justifyContent: "center",
  },
  toggleButton: {
    width: "100%",
    marginBottom: 20
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#FFFFFF",
    },
    secondary: {
      main: '#000000',
    },
  },
});

const buttonsTheme = createTheme({
  palette: {
    primary: {
      main: "#DDB321",
    },
    secondary: {
      main: '#808080',
    },
    text: {
      secondary: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: "Roboto"
  },
})

export default CreateWatermark



const uploadFile = async (file: Blob, filename: string, onUploadProgress: (loaded: number, total: number) => void): Promise<string> => {
  return new Promise(async (resolve, reject) => {
    const formData = new FormData
    formData.append('image', file, filename)
    const uploadUrl = `${process.env.REACT_APP_API_HOST || ""}/api/upload`
    await axios.put(uploadUrl, formData, {
      onUploadProgress: ({ loaded, total }) => {
        if (loaded && total)
          onUploadProgress(loaded, total)
      },
      withCredentials: true
    }).then(function (response) {
      resolve(response.data.fullPath);
    }).catch(function (error) {
      reject(error)
      console.log(error)
    })
  });


}
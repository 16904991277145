import { Button, Typography, Toolbar, Box, AppBar, Container, Link } from '@mui/material';
import { useNavigate } from "react-router-dom";
import Image from 'react-bootstrap/Image';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { NavbarProps } from '../../features/types/types';
import { CSSProperties, useContext } from 'react';
import AuthContext from '../../auth/auth';
import useMediaQuery from '../../features/mediaQueries/useMediaQuery';

const Navbar = ({ setLoginStatus, onAction, currentAction }: NavbarProps) => {
  const navigate = useNavigate();

  const { logout } = useContext(AuthContext);

  const smallHeight = useMediaQuery("(max-height: 830px)")

  const OnLogout = async () => {
    await logout();
  }

  return (
    <div style={styles.navbarWrapper}>
      <ThemeProvider theme={theme}>
        <AppBar position="static" style={styles.appBar} elevation={0} >
          <Container maxWidth="xl">
            <Toolbar disableGutters style={styles.toolbar}>
              <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: "center", alignItems: "center" }}>
                <Image src={smallHeight ? 'logo96.png' : 'logo192.png'} alt='lamborghini logo'></Image>
                <Typography variant={smallHeight ? "h5" : "h4"} component="div"
                  sx={{
                    mr: 2,
                    color: "#FFFFFF",

                    fontFamily: "Roboto",
                    letterSpacing: 3,
                    fontWeight: "bold"
                  }}
                >
                  LAMBORGHINI IMAGE PROTECTION
                </Typography>
              </Box>
              <Box >
                <ThemeProvider theme={logoutTheme}>
                  {currentAction == 'downloads' ?
                    <Button onClick={() => onAction?.(undefined)} variant="outlined">
                      Back
                    </Button>
                    : <Button onClick={() => onAction?.('downloads')} variant="outlined">
                      Downloads
                    </Button>}
                  <Button onClick={OnLogout} variant="outlined">
                    Logout
                  </Button>
                </ThemeProvider>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </ThemeProvider>
    </div>
  );
}

export default Navbar

const styles: Record<string, CSSProperties> = {
  navbarWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

  },
  navbarTypography: {
    width: "100%"
  },
  barContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  appBar: {
    color: "#181818",
    boxShadow: '0px'
  },
  toolbar: {
    backgroundColor: '#181818',
    boxShadow: '0px'
  },
  logout: {
    textDecoration: "none",
    color: "#DDB321",
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#181818",
    },
    secondary: {
      main: '#FFFFFF',
    },
  },
});

const logoutTheme = createTheme({
  palette: {
    primary: {
      main: "#DDB321",
    }
  },
});
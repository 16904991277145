import * as piexif from 'piexif'
import Tesseract from 'tesseract.js';

/*jshint esversion: 8 */
const MARGIN = 1.1;
const FONT_H = 0.065;
const ASPECT_R = 0.6;

// const processingCanvas = document.getElementById('processing-canvas');
// const processingCtx = processingCanvas.getContext('2d');
const dtStr = (new Date()).toLocaleDateString(navigator.language || "en-US");


let debounceTimeout = null;

export const extractContext = (canvas) => canvas.getContext('2d');

function _resizeCanvasesToImg(img, processingCanvas, txtCanvas) {
    processingCanvas.width = img.width;
    processingCanvas.height = img.height;
    txtCanvas.width = img.width;
    txtCanvas.height = img.height;
}

function _loadDefaultImg(processingCtx) {
    const defaultImg = new Image();
    defaultImg.src = './munnar.jpg';
    defaultImg.onload = function () {
        _resizeCanvasesToImg(defaultImg);
        processingCtx.drawImage(defaultImg, 0, 0);
    };
}

function getTextSquare(txt, font_h) {
    if (!txt.length) {
        return {
            lineArr: [],
            numLines: 0,
            width: 10,
            height: 10,
        };
    }

    const maxLineLen = Math.ceil(Math.sqrt(txt.length) * 2);
    const lineArr = [];
    const wordArr = txt.split(' ');

    while (wordArr.length) {
        let line = "";
        while (wordArr.length && line.length < maxLineLen) {
            line += wordArr.shift() + ' ';
        }
        lineArr.push(line.trim());
    }

    const actualMaxLineLen = Math.max(...(lineArr.map(s => s.length)));
    return {
        lineArr: lineArr,
        numLines: lineArr.length,
        width: Math.ceil(font_h * ASPECT_R * actualMaxLineLen),
        height: font_h * lineArr.length
    };
}

function _writeOnTxtCanvas(txtToEmbed, font_h, txtCanvas, txtCtx) {
    txtCtx.clearRect(0, 0, txtCanvas.width, txtCanvas.height);
    txtCtx.font = "normal normal 100 " + font_h.toString() + "px Arial";
    // txtCtx.letterSpacing = '3px';
    const squareTxt = getTextSquare(txtToEmbed, font_h);
    //console.log("watermark", squareTxt.lineArr)
    for (var i = 0; i < txtCanvas.height / squareTxt.height; i++) {
        for (var j = 0; j < txtCanvas.width / squareTxt.width; j++) {
            for (var k = 0; k < squareTxt.numLines; k++) {
                txtCtx.fillText(
                    squareTxt.lineArr[k],
                    5 + j * squareTxt.width * MARGIN,
                    5 + i * squareTxt.height * MARGIN + (k + 1) * font_h);
            }
        }
    }
}

export const debounceGen = function debounceGen() {
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(generateWatermark, 250);
}

function makeid(length) {
    var result           = '';
    // var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var characters       = 'bcfhjknprstvwxy23789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength ));
    }
    return result;
}


export function generateWatermark(txtCanvas, txtCtx, processingCanvas, processingCtx) {
    txtCanvas.style.opacity = 0.5;
    console.log(txtCanvas);
    txtCanvas.style.animation = 'none';

    var txtToEmbed = makeid(6);

    console.log("text to be embeded", txtToEmbed);

    _writeOnTxtCanvas(txtToEmbed, txtCanvas.height * FONT_H, txtCanvas, txtCtx);
    _addWatermark(processingCanvas, processingCtx, txtCanvas, txtCtx);
    return txtToEmbed;
}

function _addWatermark(processingCanvas, processingCtx, txtCanvas, txtCtx) {
    var processingImg = processingCtx.getImageData(0, 0, processingCanvas.width, processingCanvas.height);
    var txtImg = txtCtx.getImageData(0, 0, txtCanvas.width, txtCanvas.height);

    var processingData = processingImg.data;
    var txtData = txtImg.data;

   

    for (var i = 0; i < txtData.length; i += 4) {
        if (txtData[i + 3]) {
            processingData[i] = processingData[i] & ~1;
            processingData[i + 1] = processingData[i + 1] & ~1;
            processingData[i + 2] = processingData[i + 2] & ~1;
        }
        else {
            processingData[i] = processingData[i] | 1;
            processingData[i + 1] = processingData[i + 1] | 1;
            processingData[i + 2] = processingData[i + 2] | 1;
        }
    }
    processingCtx.putImageData(processingImg, 0, 0);
}

export const extractWatermark = async function extractWatermark(processingCanvas, processingCtx, txtCanvas, txtCtx, _updateRetriveProgress,giveMeBlob) {
    var processingImg = processingCtx.getImageData(0, 0, processingCanvas.width, processingCanvas.height);
    var outImg = txtCtx.getImageData(0, 0, txtCanvas.width, txtCanvas.height);
    var processingData = processingImg.data;
    var outData = outImg.data;
    var odds = 0;
    var evens = 0;

    for (var i = 0; i < processingData.length; i += 4) {
        let voting = [processingData[i] % 2,
        processingData[i + 1] % 2,
        processingData[i + 2] % 2];
        let sum = voting.reduce((a, b) => a + b, 0);

        if (sum >= 2) {
            outData[i] = 235;
            outData[i + 1] = 235;
            outData[i + 2] = 235;
            outData[i + 3] = 255;
            odds++;
        }
        else {
            outData[i] = 20;
            outData[i + 1] = 20;
            outData[i + 2] = 20;
            outData[i + 3] = 255;
            evens++;
        }
    }
    console.log("extracting", evens, odds);
    processingCtx.putImageData(outImg, 0, 0);
    if(giveMeBlob){
        const imgData = await new Promise(res => processingCanvas.toBlob(res, "image/jpeg"));
        return imgData;
    } else {
        const imgData = processingCanvas.toDataURL("image/jpeg", 1.0);
        return imgData;
    }    
    
      
}


export const addWatermarkUI = function addWatermarkUI() {
    // [...document.getElementsByClassName("instructions")].map(e => e.style.display = 'flex');
    // [...document.getElementsByClassName("add-instruction")].map(e => e.style.display = 'flex');
    // [...document.getElementsByClassName("retrieve-instruction")].map(e => e.style.display = 'none');
    // document.getElementById("add-tab").classList.add('selected-tab');
    // document.getElementById("retrieve-tab").classList.remove('selected-tab');
}

export const retrieveWatermarkUI = function retrieveWatermarkUI() {
    // [...document.getElementsByClassName("instructions")].map(e => e.style.display = 'flex');
    // [...document.getElementsByClassName("add-instruction")].map(e => e.style.display = 'none');
    // [...document.getElementsByClassName("retrieve-instruction")].map(e => e.style.display = 'flex');
    // document.getElementById("add-tab").classList.remove('selected-tab');
    // document.getElementById("retrieve-tab").classList.add('selected-tab');
}

function init() {
    const dtStr = (new Date()).toLocaleDateString(navigator.language || "en-US");
    // const watermarkDefault = `[add your tag]`;
    // document.getElementById("edit-watermark").value = watermarkDefault;
    // _loadDefaultImg();

}
init();

import { ModalPreviewProps } from "../../features/types/types";
import "./styles.css";
import { CSSProperties, useEffect, useRef, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Button } from "@mui/material";

const ModalPreview = ({ uploadedImages, selectedJournalist, setSelectedJournalist, setCarouselPosition, setPreview, excelTags, tag, imageBlobs, setImageBlobs, setRedundantJournalists, scrollPos, setScrollPos, genericLoading, setGenericLoading }: ModalPreviewProps) => {

    const [inputValue, setInputValue] = useState("");

    const divRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (divRef.current) {
            divRef.current.scrollTop = scrollPos;
        }
    }, [scrollPos]);

    function filterJournalists(journalist: string) {
        if(journalist.toLowerCase().includes(inputValue.toLowerCase())){
            return true
        } else {
            return false
        }
    }

    let journalists: any[][] = [];
    let allJournalistsWithImages = new Array()

    if(uploadedImages) {
        for (let i = 0; i < excelTags.length; i++) {
            for (let j = 0; j < uploadedImages.length; j++) {
                const tempTag = tag[(i * uploadedImages.length) + j]
                if (tempTag && tempTag?.length > 0) {
                    allJournalistsWithImages.push([tempTag, uploadedImages[j]])
                }
            }
        }
    }

    let numberOfImages: number

    numberOfImages = uploadedImages ? uploadedImages.length : null
    const numberOfElements: number = allJournalistsWithImages.length;

    function groupJournalists() {
        let tempArray = []
        for(let i = 0; i < numberOfElements; i++) {
            tempArray.push(allJournalistsWithImages[i])
            if (tempArray.length === numberOfImages) {
                journalists.push(tempArray);
                tempArray = [];
            }
        }
    }

    if(allJournalistsWithImages.length > 0) groupJournalists()
    
    async function displayImgsPerJournalist(journalistIndex: number) {
        const journalistData = journalists[journalistIndex];
        const newBlobs = new Map(imageBlobs);

        for (let data of journalistData) {
            const blobURL = data[1];
            const blob = await fetchBlobFromURL(blobURL);
            newBlobs.set(blobURL, blob);
        }

        setImageBlobs(newBlobs);
        setSelectedJournalist(journalistIndex);
    }

    function checkJournalistsRedundancy() {
        let singleJournalists = Array()
        journalists.forEach((journalistData, index) => {
            singleJournalists.push(journalistData[0][0][0])
            let journalistsToCheck = new Set(singleJournalists)
            if(singleJournalists.length !== journalistsToCheck.size) {
                setRedundantJournalists(true);
            }
        });
    }

    useEffect(() => {
        checkJournalistsRedundancy()
    })

    async function fetchBlobFromURL(blobURL: string) {
        const response = await fetch(blobURL);
        const blobData = await response.blob();
        return blobData;
    }

    useEffect(() => {
        if(genericLoading) {
            setTimeout(() => {
                setPreview(false)
            }, 20)
        }
    }, [genericLoading])

    function goToCarousel(journalistImageIndex: number, journalistIndex: number) {
        if (divRef.current) {
            setScrollPos(divRef.current.scrollTop);
        }
        if(selectedJournalist === 0) {
            setCarouselPosition({image: journalistImageIndex, journalist: journalistIndex});
        } else {
            if(selectedJournalist) {
                setCarouselPosition({image: journalistImageIndex, journalist: journalistIndex})
            }
        }
        setGenericLoading(true)
    }
    
    return (
        <>
            {selectedJournalist === null &&
                <div className="journalists-list-wrapper">
                    <input type="text" id="search-journalist" placeholder="Search" name={`input_${Math.random()}`} value={inputValue} onChange={e => setInputValue(e.target.value)} />
                    
                    <div className="journalists-grid">
                        {journalists.map((journalist, index) => {
                            const isButtonVisible = !inputValue || filterJournalists(journalist[0][0][0]);

                            return (
                                <button
                                key={index}
                                onClick={() => displayImgsPerJournalist(index)}       
                                className={`journalist-button ${isButtonVisible ? 'visible' : 'hidden'}`} 
                                >
                                    {journalist[0][0][0]}
                                </button>
                            );
                        })}
                    </div>
                </div>
            }

            {selectedJournalist !== null && 
                <div className="images-list-wrapper">
                    <ThemeProvider theme={buttonsTheme}>
                        { <Button
                            variant="outlined"
                            color="primary"
                            style={styles.buttonBackToGrid}
                            onClick={async () => setCarouselPosition({image: 0}, setSelectedJournalist(null), setScrollPos(0))} >
                            Back to journalists
                        </Button> }
                    </ThemeProvider>
                    <div className="images-list" ref={divRef}>
                        {journalists[selectedJournalist].map((data, idx) => {
                            const blob = imageBlobs.get(data[1]);
                            return (
                                <button key={idx} className="journalist-images-data" onClick={() => goToCarousel(idx, selectedJournalist)}>
                                    <div className="journalist-preview-tags-div">
                                        <p className="tags-index">{idx+1}.</p>
                                        {data[0].map((tag: string, tagKey: number) => {
                                            return <p key={tagKey} className="preview-image-tags">{tag}</p>
                                        })}
                                    </div>
                                    {blob && <img  className="preview-image" src={URL.createObjectURL(blob)} alt="Uploaded" loading="lazy"/>}
                                </button>
                            );
                        })}
                    </div>
                </div>
            }
        </>
    )
}


export default ModalPreview;

const styles: Record<string, CSSProperties> = {
    buttonBackToGrid: {
        display: "block",
        borderRadius: 10,
        fontFamily: "Roboto",
        margin: "5px 5px 0px 5px",
    },
}

const buttonsTheme = createTheme({
    palette: {
      primary: {
        main: "#DDB321",
      },
      secondary: {
        main: '#808080',
      },
      text: {
        secondary: '#FFFFFF',
      },
    },
    typography: {
      fontFamily: "Roboto"
    },
  })
import { createTheme } from '@mui/material/styles';

const datePickerTheme = createTheme({
  components: {
    MuiInputBase: {
        styleOverrides: {
            root: {
                backgroundColor: "#fff",
                borderRadius: "10px !important",
                '&.Mui-disabled': {
                    backgroundColor: '#e5e5e5',
                },
            },
            input: {
                borderRadius: "15px",
                backgroundColor: '#fff',
                '&.Mui-disabled': {
                    backgroundColor: '#e5e5e5',
                },
                '&.Mui-focused': {
                  color: '#fff',
                },
            },
        },
    },
    MuiOutlinedInput: {
        styleOverrides: {
            root: {
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#e5e5e5',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#e5e5e5',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline, &:hover.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#e5e5e5',
                },
            },
        },
    },
    MuiInputAdornment: {
        styleOverrides: {
            root: {
                '& .MuiIconButton-edgeEnd': {
                    color: "#DDB321",
                    border: "1px solid",
                    backgroundColor: '#fff',
                },
                '&:hover .MuiIconButton-edgeEnd': {
                    color: "#e9c46a" 
                },
            },
        },
    },
  },
});

export default datePickerTheme;

import { Button, createTheme, ThemeProvider } from '@mui/material'
import { unzip } from 'zlib';
import { LoadImagesProps } from '../../features/types/types';
import { CSSProperties } from 'react';

const InputImage = ({  zippedFolder, setZippedFolder, inputId, setGenericLoading }: LoadImagesProps) => {

  let disabledButton = zippedFolder
  return (
    <>
      <div style={styles.container}>
        <p style={{ color: zippedFolder ? "#6c757d" : "#FFF", marginTop: "1px", fontSize: 18, fontWeight: "bold", paddingTop: 15, display: "flex", flexDirection: "row" }}>Select images to upload from zipped folder</p>
        <ThemeProvider theme={buttonsTheme}>
          <Button variant="outlined" color={disabledButton ? "secondary" : "primary"} component="label" sx={{ height: 36 }}>
            Upload zip
            <input
              disabled={disabledButton}
              hidden
              accept="application/zip"
              id={inputId}
              type="file"
              name="inputFolder"
              onChange={(event) => {setZippedFolder(event); setGenericLoading(true)}} />
          </Button>
        </ThemeProvider>
      </div> 
    </>
  );
};

const styles: Record<string, CSSProperties> = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  InputImage: {
    fontFamily: "Roboto"
  },
}

const buttonsTheme = createTheme({
  palette: {
    primary: {
      main: "#DDB321",
    },
    secondary: {
      main: '#6c757d',
    },
    text: {
      secondary: '#6c757d',
    },
  },
})

export default InputImage
import { useEffect, useState } from 'react'

function useMediaQuery(query: any, defaultMatches = window.matchMedia(query)) {
    const [matches, setMatches] = useState<MediaQueryList>(defaultMatches)

    useEffect(() => {
        const media: any = window.matchMedia(query)

        if (media.matches !== matches) setMatches(media.matches)

        const listener = () => setMatches(media.matches)

        media.addListener(listener)

    }, [query, matches])

    return matches
}

export default useMediaQuery
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ExpirationDateProps } from '../../features/types/types';
import { ThemeProvider } from '@mui/material/styles';
import datePickerTheme from '../../features/themes/datePickerTheme';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import './styles.css';

const ExpirationDate = ({expirationDate, setExpirationDate, file }: ExpirationDateProps) => {

  return (
    <>
    <ThemeProvider theme={datePickerTheme}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-gb'>
        <DatePicker value={expirationDate} 
                    onChange={(newValue: any) => setExpirationDate(newValue)} 
                    disabled={file ? false : true}
                    shouldDisableDate={(date) => { 
                      return !date.isAfter(dayjs(), 'day');
                    }}
        />
      </LocalizationProvider> 
    </ThemeProvider>
    </>
  );
}

export default ExpirationDate;
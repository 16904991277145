import { DisplayImageProps } from '../../features/types/types'
import "./styles.css"

const DisplayImage = ({ carImage, quantity, index, style }: DisplayImageProps) => {

  return (
    carImage && (
      <>
        {index}
        <img alt="Lambo-Image" className="imageNoTags" src={carImage?.target ? URL.createObjectURL(carImage.target.files[0]) : carImage} loading="lazy" />
      </>
    )
  )
}

export default DisplayImage
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import CssBaseline from '@mui/material/CssBaseline';


// const GlobalStyles = createGlobalStyle`
//   html {
//     --swiper-theme-color: #DDB321 !important;
//   }
// `;


import { globalCss } from '@stitches/react';

const globalStyles = globalCss({
  html: {
    "--swiper-theme-color": '#DDB321 !important',
  },
});


const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <CssBaseline />
    {
      globalStyles()
    }
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, {  useEffect, useState} from 'react'
import { NotesFieldProps } from '../../features/types/types';
import { CSSProperties } from 'react';
import "./styles.css"

const NotesField = ({ notes, index, setNotes }: NotesFieldProps) => {

    const [localValue, setLocalValue] = useState(notes[index] || '');

    const inputRef = React.useRef<HTMLTextAreaElement>(null);

    const [isFocused, setIsFocused] = useState(false);

    const [fontSize, setFontSize] = useState(16);

    let initialFontSize = 18;

    useEffect(() => {
        const textarea = inputRef.current;
        if (textarea) {
            let gap = textarea.scrollHeight - textarea.clientHeight;
      
            if (gap > 0 && fontSize > 10) {
                setFontSize(fontSize - 1);
            } else if (fontSize < initialFontSize) {
                textarea.style.fontSize = `${fontSize + 1}px`;
            
                if (textarea.scrollHeight === textarea.clientHeight) {
                    setFontSize(fontSize + 1);
                }
            
                textarea.style.fontSize = `${fontSize}px`;
            }
        }
    });
  
    useEffect(() => {
      setLocalValue(notes[index] || '');
    }, [notes, index]);

    useEffect(() => {
        if(!isFocused) {
            setNotes((prevNotes: any) => {
                const updatedNotes = [...prevNotes];
                updatedNotes[index] = localValue;
                return updatedNotes;
            });
        }
    }, [isFocused])
  
    const handleStringChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const newValue = e.target.value;
      setLocalValue(newValue);
    }

    const styles: Record<string, CSSProperties> = {
        inputField: {
            fontSize: `${fontSize}px`,
        }
    }
  
    return (
        <textarea 
            ref={inputRef}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            style={styles.inputField}
            className="notes-text-area"
            value={localValue}
            onChange={handleStringChange}
            maxLength={300}
            placeholder='Notes'
        />
    );
  }

export default NotesField;
import React, { useEffect, useContext, useState, CSSProperties } from 'react'
import { useNavigate } from "react-router-dom";
import { Button, TextField, Typography } from '@mui/material';
import { LoginProps } from '../../features/types/types';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Image from 'react-bootstrap/Image';
import axios from 'axios';
import AuthContext from '../../auth/auth';
import useMediaQuery from '../../features/mediaQueries/useMediaQuery';

const LoginForm = () => {

    const small = useMediaQuery("(max-height: 700px)")
    const mobile = useMediaQuery("(max-width: 510px)")

    const { login } = useContext(AuthContext);

    async function OnLogin() {
        await login();
    }

    return (
        <div style={styles.wrapper}>
            <div style={small ? styles.smallInnerFormContainer : mobile ? styles.mobileInnerFormContainer : styles.innerFormContainer}>
                <div>
                    <Image src='logo256.png' alt='Login_background_image'></Image>
                </div>

                <div style={styles.loginButtonContainer}>
                    <Button onClick={OnLogin} style={styles.loginButton}>Login</Button>
                </div>
            </div>
        </div>
    )

}
const wallpaperImage = "drivingLambo.jpg"

const styles: Record<string, CSSProperties> = {
    wrapper: {
        background: `linear-gradient(300deg, transparent, transparent 60%, white calc(60% + 0px)), white 40% url(${wallpaperImage}`,
        backgroundSize: 'cover',
        position: "absolute",
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
    },

    innerFormContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // justifyContent: "space-around",
        zIndex: 1,
        marginTop: 150,
        marginLeft: 150,
        paddingLeft: 50,
        paddingRight: 50
    },
    smallInnerFormContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        zIndex: 1,
        marginLeft: 50,
        paddingTop: 40,
        paddingLeft: 50,
        paddingRight: 50
    },
    mobileInnerFormContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        zIndex: 1,
        marginLeft: 75,
        marginTop: 50
    },
    form: {
        paddingTop: 30,
        paddingBottom: 40,
    },
    usernameFieldContainer: {
        marginBottom: 10,
    },
    passwordFieldContainer: {

    },
    fields: {
        color: "white"
    },
    loginButton: {
        color: "#FFF",
        backgroundColor: "#000",
        fontFamily: "Roboto",
        fontSize: 18,
        letterSpacing: 4,
        paddingLeft: 30,
        paddingRight: 30,
        borderRadius: 10,
        margin: 5
    },
}

const buttonsTheme = createTheme({
    palette: {
        primary: {
            main: "#000",
        }
    },
})

export default LoginForm;
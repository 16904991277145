import { useContext } from "react";
import { Navigate } from "react-router-dom";

import AuthContext from "./auth";

const ProtectedRoute = ({ children, accessBy }) => {
  const { user } = useContext(AuthContext);
  if (accessBy == "non-authenticated") {
    if (user) {
      return null;
    } else {
      return children;
    }
  } else if (accessBy === "authenticated") {
    if (user) {
      return children;
    } else {
      return null;
    }
  }
};

export default ProtectedRoute;
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { TextField, Autocomplete, AutocompleteChangeReason } from '@mui/material'
import { CreateWaterMarkStateTypes, TagPlaceEnum, TagProps } from '../../features/types/types'
import Chip from '@mui/material/Chip';
import { CSSProperties } from 'react';

const Tag = ({ tag, setTags, index, TagPlace, file, excelTags, checkImagesTagsNumber, uploadedImages }: TagProps) => {

  let disabledField: boolean

  file && excelTags ? disabledField = false : disabledField = true

  const onChange = (event: any, values: (string | string[])[], reason: AutocompleteChangeReason) => {
    setTags((oldTags: CreateWaterMarkStateTypes) => {
      let newState = JSON.parse(JSON.stringify(oldTags))

      if (TagPlace === TagPlaceEnum.Create) {
        newState[TagPlace] = values

        if (reason === "createOption") {
          let [addedItem] = values.filter(e => !oldTags[TagPlace].includes(e as string))
          newState[TagPlaceEnum.ImageSide].forEach((e: string[]) => {
            if (e && !e.includes(addedItem as string)) { e.push(addedItem as string) }
          })
        } else if (reason === "removeOption") {
          let [deletedItem] = oldTags[TagPlace].filter(e => !values.includes(e as string))
          newState[TagPlaceEnum.ImageSide].forEach((e: string[]) => {
            if (e && e.includes(deletedItem as string)) { e.splice(e.findIndex(i => i === deletedItem), 1) }
          })
        }
      } else if (TagPlace === TagPlaceEnum.ImageSide && typeof (index) !== 'undefined') {
        newState[TagPlace][index] = values
      }

      return newState
    })
  }

  (!file || uploadedImages && checkImagesTagsNumber != uploadedImages.length) ? disabledField = true : disabledField = false

  return (
    <>
      {/* {disabledField ? '' : */}

      <ThemeProvider theme={theme}>
        <Autocomplete
          multiple
          disabled={disabledField}
          options={[]}
          value={tag}
          freeSolo
          clearIcon={false}
          autoSelect
          onKeyDown={e => {
            //@ts-ignore
            if (e.key === ' ' || e.key === 'Spacebar') {
              //@ts-ignore                   
              const ke = new KeyboardEvent('keydown', {
                bubbles: true, cancelable: true, keyCode: 13
              });
              //@ts-ignore 
              e.target.dispatchEvent(ke);
            }
          }}
          renderTags={(value, getTagProps) =>
            //@ts-ignore
            value.map((option: string, index: number) => (
              <Chip variant="outlined" label={option} {...getTagProps({ index })} />
            ))
          }
          onChange={(event, values, reason) => onChange(event, values, reason)}
          renderInput={(tags) => (
            <TextField
              {...tags}
              sx={{
                '& .MuiFilledInput-underline:before': {
                  borderBottom: 'none',
                },
                '& .MuiFilledInput-underline:after': {
                  borderBottom: 'none',
                },
                '&:hover .MuiFilledInput-underline:before': {
                  borderBottom: 'none',
                },
                '&:hover .MuiFilledInput-underline:after': {
                  borderBottom: 'none',
                },
                '&:active .MuiFilledInput-underline': {
                  borderBottom: 'none',
                },
                '&:disabled .MuiFilledInput-underline': {
                  borderBottom: 'none',
                },
                '&:disabled .MuiFilledInput-underline:before': {
                  borderBottom: 'none',
                },
                '&:disabled .MuiFilledInput-underline:after': {
                  borderBottom: 'none',
                },
              }}
              variant="filled"
              style={styles.tagInput}
              label="Tag"
              placeholder="Insert tag"
            />
          )}
        />
      </ThemeProvider>
      {/* } */}
    </>

  )
}

const styles: Record<string, CSSProperties> = {
  tagInput: {
    backgroundColor: "#FFFFFF",
    height: "60px",
    overflowX: "auto",
    borderRadius: "10px",
    borderBottom: "none",
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#000",
    },
    secondary: {
      main: '#000000',
    },
  },
});

export default Tag
import Login from './components/Login/Login'
import CreateWatermark from './components/CreateWatermark'
import {useState} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { AuthContextProvider } from './auth/auth';
import ProtectedRoute from './auth/ProtectedRoute';


export default function App() {
    
    return(
            <Router>
                <AuthContextProvider>
                        <Routes>
                    <Route path="/" element={<>
                                <ProtectedRoute accessBy="authenticated">
                                    <CreateWatermark/>
                                </ProtectedRoute>
                        <ProtectedRoute accessBy="non-authenticated">
                            <Login />
                        </ProtectedRoute>
                    </>
                            }/>
                        </Routes>
                </AuthContextProvider>
            </Router>
    )
}

export type DisplayImageProps = {
    carImage: any
    quantity?: number | string
    index?: number | string
    style?: any
}

export type ImagesListProps = {
    carImage: any
    setCarImage: Function
    quantity: number
    tag: string[][]
    setTags: Function
    excelTags: string[][]
    overrideTiffCanvas: any //for tiff images
    file: any
    uploadedImages: any
    checkImagesTagsNumber: number
    setJournalistsWithImages: any
    imagePosition: number,
    journalistPosition: number,
    preview: boolean
    setPreview: Function
    notes: Array<string>
    setNotes: Function
    setGenericLoading: Function
}

export type LoadImagesProps = {
    inputId: string
    zippedFolder: any
    setZippedFolder: Function
    setGenericLoading: Function
}

export type InputImageProps = {
    carImage: string | null
    setCarImage: Function
    inputId: string
}

export type LoadFileProps = {
    setExcelTags: Function
    file: any | null
    setFile: Function
    excelTags: string[]
    inputId: string
    uploadedImages: any
    carImage: any
    setQuantity: Function
    zippedFolder: any
    setCheckImagesTagsNumber: Function
    checkImagesTagsNumber: number
    setNotes: Function
}

export enum TagPlaceEnum {
    Create = "CreateSide",
    ImageSide = "ImageSide"
}

export type TagProps = {
    tag: Array<string>
    setTags: Function
    TagPlace: TagPlaceEnum
    index?: string | number
    file: any
    excelTags: string[][]
    uploadedImages: any
    checkImagesTagsNumber: number
}

export type NavbarProps = {
    setLoginStatus?: Function,
    onAction?: (action?: string) => void,
    currentAction?: string
}

export type WQuantityProps = {
    quantity: number | string
    setQuantity: Function
    file: any
    uploadedImages: any
    carImage: any
}

export type CreateWaterMarkStateTypes = {
    ImageSide: string[][]
    CreateSide: string[]
}

export type LoginProps = {
    loginStatus: boolean
    setLoginStatus: Function
}

export type ModalPreviewProps = {
    uploadedImages: any
    selectedJournalist: number | null
    setSelectedJournalist: Function
    setPreview: Function
    setCarouselPosition: Function
    tag: string[][]
    excelTags: string[][]
    imageBlobs: any,
    setImageBlobs: Function
    setRedundantJournalists: Function
    scrollPos: number
    setScrollPos: Function
    genericLoading: boolean
    setGenericLoading: Function
}

export type NotesFieldProps = {
    notes: string[];
    setNotes: Function
    index: number
}

export type ExpirationDateProps = {
    expirationDate: any;
    setExpirationDate: any;
    file: any
}

export type PopupDialogTypes = {
    open: boolean
    setOpen: Function
    message: String
    confirmMessage: String
    dismissMessage: String
    onConfirm: Function
    onDismiss: Function
    loading: boolean
}
import { DisabledByDefault } from '@mui/icons-material';
import { Button, createTheme, Input, ThemeProvider } from '@mui/material'
import { InputImageProps } from '../../features/types/types';
import { CSSProperties } from 'react';



const InputImage = ({ carImage, setCarImage, inputId }: InputImageProps) => {

  let disabledButton: boolean
  carImage ? disabledButton = true : disabledButton = false

  return (
    <div style={styles.container}>
      <ThemeProvider theme={buttonsTheme}>
        <Button variant="outlined" color={disabledButton ? "secondary" : "primary"} component="label" sx={{ height: 36 }}>
          Select Image
          <input hidden accept="image/*"
            disabled={disabledButton}
            id={inputId}
            type="file"
            name="Image"
            onChange={(event) => setCarImage(event)} />
        </Button>
      </ThemeProvider>
    </div>
  );
};

const styles: Record<string, CSSProperties> = {
  container: {
    display: "flex",
    alignItems: "center"
  },
  InputImage: {
    fontFamily: "Roboto"
  },
}

const buttonsTheme = createTheme({
  palette: {
    primary: {
      main: "#DDB321",
    },
    secondary: {
      main: '#808080',
    },
    text: {
      secondary: '#00FF00',
    },
  },
})

export default InputImage
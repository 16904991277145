import axios from "axios";
import { createContext, useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {

    const [user, setUser] = useState();
    useEffect(() => {
        axios.get(
            (process.env.REACT_APP_API_HOST || "") +
            "/me",
            { withCredentials: true }
        ).then(({ data }) => {
            setUser(data);
        });
    }, [])

    const login = async (payload) => {
        window.location.href = (process.env.REACT_APP_API_HOST || "") +
            "/login"
    };
    const logout = async () => {
        window.location.href = (process.env.REACT_APP_API_HOST || "") +
            "/logout"
    };

    return (
        <AuthContext.Provider value={{ user, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;